import React from 'react';
import { List, ListSubheader } from '@mui/material';
import ErrorBoundary from 'sensortower-components/src/base-components/ErrorBoundary';
import ContentModule from 'sensortower-components/src/base-components/ContentModule';
import { NavigationItemLinkProps } from '../NavigationItemLink';
import { sidekick } from '../../utils/sidekick';

export interface NavigationItemGroupProps extends NavigationItemLinkProps {}

export const NavigationItemGroup = ({ text, subNavigation, sidekickLookup }: NavigationItemGroupProps) => {
  return (
    <ErrorBoundary>
      <List
        subheader={
          <ListSubheader sx={{ display: { xs: 'none', lg: 'block' } }} data-testid="NavigationItemGroup-subheader">
            {text}
          </ListSubheader>
        }
        {...sidekick(sidekickLookup)}
        data-testid="NavigationItemGroup">
        {subNavigation?.map((subNav) => (
          <ContentModule key={subNav.id} {...subNav} variant="link" />
        ))}
      </List>
    </ErrorBoundary>
  );
};

export default NavigationItemGroup;
